
import {Component, Prop, Vue} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {ItemArrayManagerTableHeader} from "@/interface/TableHeader";

@Component({
  components: {FormDialog, DeleteDialog},
})
export default class ItemArrayManager extends Vue {
  @Prop() hideButton !: boolean;
  @Prop({required: true}) value!: Array<any>;
  @Prop({required: true}) headers!: Array<ItemArrayManagerTableHeader>;
  @Prop({required: true}) title!: string;
  @Prop({required: false}) crudTitle!: string;
  @Prop({required: false}) copyTitle!: string;
  @Prop({required: false}) deleteUrl!: string;
  @Prop({default: false}) color!: string;
  @Prop({default: false}) btncolor!: string;
  @Prop({default: "Ekle"}) saveLabel!: string;
  @Prop({default: "table"}) type?: "table" | "list" | "text";
  @Prop({default: false}) editable: any;
  @Prop({default: false}) detail: any;
  @Prop({default: false}) addable: any;
  @Prop({default: false}) deletable: any;
  @Prop({default: false}) copiable: any;
  @Prop({default: false}) crud: any;
  @Prop() action!: string;
  @Prop() defaultValue!: any;

  get isTable(): boolean {
    return this.type === "table";
  }

  get isText(): boolean {
    return this.type === "text";
  }

  get isList(): boolean {
    return this.type === "list";
  }

  getDescendantProp(obj: any, desc: string) {
      let arr: Array<string> = desc.split(".");
      while (arr?.length) {
        let index = arr.shift();

        if (typeof index == "string") {
          obj = obj[index];
        }
      }
      return obj;
    }

  get isDeletable(): boolean {
    return (this.deletable === "" || this.deletable || this.crud || this.crud === "");
  }

  get isEditable(): boolean {
    return (this.editable === "" || this.editable || this.crud || this.crud === "");
  }

  get isDetail(): boolean {
    return this.detail === "" || this.detail;
  }

  get isAddable(): boolean {
    return this.addable === "" || this.addable || this.crud || this.crud === "";
  }

  get isCopiable(): boolean {
    return this.copiable === "" || this.copiable;
  }

  get isActionExist(): boolean {
    return (this.isAddable || this.isDeletable || this.isEditable || this.isDetail);
  }

  get actionsWidth(): number {
    return (
        20 +
        (this.isDeletable ? 32 : 0) +
        (this.isEditable ? 32 : 0) +
        (this.isDetail ? 32 : 0) +
        (this.isCopiable ? 32 : 0)
    );
  }

  deleteItem(item: any) {
    const index = this.value.indexOf(item);
    let arr = new Array<any>();
    if (index > -1) {
      if (this.deleteUrl && item.id) {
        this.$http.delete(`/api/v1/${this.deleteUrl}/${item.id}`);
      }
      this.$emit("input", arr.concat(this.value.slice(0, index)).concat(this.value.slice(index + 1)));
    }
  }

  updateItem(o: any, index: number) {
    if (index > -1) {
      if (Array.isArray(this.value)) {
        const newState = this.value.map((obj, objIndex) => {
          if (obj.id && obj.id == o.id) {
            o.changed=true;
            return o;
          }
          if (objIndex==index){
            return o;
          }
          return obj;
        });
        this.$emit("input", newState);
      } else {
        this.$emit("input", [o]);
      }
    }
  }

  addItem(o: any) {
    if (!this.value) {
      this.$emit("input", [o]);
    } else {
      if (Array.isArray(this.value)) {
        this.$emit("input", o ? this.value.concat([o]) : this.value);
      } else {
        this.$emit("input", [o]);
      }
    }
  }

  copiedItem(o: any) {
    if (Array.isArray(this.value)) {
      this.$emit("input", this.value.concat(o));
    } else {
      this.$emit("input", [o]);
    }
  }
}
